import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import * as Sentry from '@sentry/react';
import { datadogRum } from '@datadog/browser-rum';
import { UploadType, namespaceMap } from 'helper/constants/constants';
import deepTrim from 'deep-trim';

import { MHErrors, MHProfileError } from 'models/MHErrors';
import { v4 as UUID } from 'uuid';
import {
  ReleaseRequest,
  ReleaseTrackRequest,
  ReleeaseArtworkRequest,
  ReleaseResponse,
  ReleaseTrackResponse,
  ReleaseListResponse,
  ReleaseArtworkResponse,
  ReleaseIngestionStatusRequest,
  ReleaseRecentListResponse,
  ReleaseLockFailureResponse,
} from 'models/Release';
import {
  TrackParticipantRequest,
  TrackAudioRequest,
  TrackRequest,
  TracklistPaginatedResponse,
  TrackResponse,
  TrackParticipantResponse,
  TrackAudioResponse,
  TrackPatchRequest,
} from 'models/Track';
import { ReleaseCount, ReleasePatchRequest } from 'models/Release';
import { Track } from 'models/Track';
import { StatementLineItem } from 'features/balance/models/Balance';
import authentication, {
  IssuerType,
} from 'helper/authentication/authentication';
import { MusicAnalyticsToken } from 'models/MusicAnalytics';
import {
  SoundfileDocRequest,
  SoundfileDocResponse,
  SoundfileGetResponseBody,
} from 'features/broadcast-monitoring/models/SoundFile';
import isString from 'lodash/isString';
import {
  ParticipantListResponse,
  ParticipantRequest,
  ParticipantResponse,
} from 'features/participants/models/Participant';
import { TrackAudioWavelyzerFeedbackResponse } from '../../../cypress/page-objects/Given';
import {
  UserFeatureResponse,
  UserProfile,
  UserProfilePatchRequest,
} from 'models/Users';
import { ParticipantRoles } from 'features/participants/hooks/useParticipantRoles';
import { ExperimentResponse } from 'models/Experiments';
import { SignedUploadUrlResponse } from 'models/SignedUploadUrlResponse';
import { logging } from 'logging/logging';

const NON_AUTHENTICATED_ROUTES = [
  '/actuator/health',
  '/exchange?issuer=gema',
  '/exchange?issuer=gemaCiam',
  '/register',
  '/featureFlags',
];

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN,
  timeout: 0,
});

export const axiosRequestMiddleware = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig<unknown>> => {
  if (config?.url && NON_AUTHENTICATED_ROUTES.includes(config.url)) {
    return config;
  }
  const user = authentication.user;
  if (!user) {
    // signOut is enough to trigger a redirect to the login page
    authentication.signOut();
    return Promise.reject({ message: 'User session has expired' });
  }

  // gets impersonated user if available
  const impersonatedUser = localStorage.getItem('impersonatedUser');

  // Trim all extra whitespace from data strings
  if (
    config.method?.toLowerCase() === 'put' ||
    config.method?.toLowerCase() === 'post'
  ) {
    config.data = deepTrim(config.data);
  }

  // getIdToken also refreshes token if the access token has expired
  const authTokenBearer = await authentication.getFirebaseIdToken();

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${authTokenBearer}`,
      ...(impersonatedUser && { 'X-Impersonate': impersonatedUser }),
    },
  };
};

instance.interceptors.request.use(axiosRequestMiddleware, (error) => {
  Sentry.captureException(error);
  datadogRum.addError(error);
  return Promise.reject(error);
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.status && error.status >= 400 && error.status < 500) {
      Sentry.captureException(error);
      datadogRum.addError(error);
    }
    return Promise.reject(error);
  }
);

/*
 * Release
 */
const release = {
  create: (
    initialReleaseData: ReleaseRequest
  ): Promise<AxiosResponse<ReleaseResponse>> =>
    instance.post(`/releases`, initialReleaseData),

  update: (
    id: string,
    releaseData: ReleaseRequest
  ): Promise<AxiosResponse<ReleaseResponse>> =>
    instance.put(`/releases/${id}`, releaseData),

  patch: (
    id: string,
    releasePatchRequest: ReleasePatchRequest
  ): Promise<AxiosResponse<ReleaseResponse>> =>
    instance.patch(`/releases/${id}`, releasePatchRequest),

  lock: (
    id: string
  ): Promise<AxiosResponse<ReleaseLockFailureResponse | undefined>> =>
    instance.post(`/releases/${id}/lock`),

  unlock: (id: string): Promise<AxiosResponse<unknown>> =>
    instance.delete(`/releases/${id}/lock`),

  submission: (id: string): Promise<AxiosResponse> =>
    instance.post(`/releases/${id}/submission`),

  get: (
    { id }: { id: string },
    config?: AxiosRequestConfig | undefined
  ): Promise<AxiosResponse<ReleaseResponse>> =>
    instance.get(`/releases/${id}`, config),

  getReleaseTracks: (
    id: string,
    config?: AxiosRequestConfig | undefined
  ): Promise<AxiosResponse<ReleaseTrackResponse[]>> =>
    instance.get(`/releases/${id}/tracks`, config),

  updateReleaseTracks: (
    id: string,
    data: ReleaseTrackRequest[]
  ): Promise<AxiosResponse<ReleaseTrackResponse[]>> =>
    instance.put(`/releases/${id}/tracks`, data),

  getAll: (
    page: number,
    size: number,
    ingestionStatus?: ReleaseIngestionStatusRequest
  ): Promise<AxiosResponse<ReleaseListResponse>> =>
    instance.get(
      `/releases?page=${page}&size=${size}${
        ingestionStatus ? `&ingestionStatus=${ingestionStatus}` : ''
      }`
    ),

  getReleaseRecentList: (
    page: number,
    size: number
  ): Promise<AxiosResponse<ReleaseRecentListResponse>> =>
    instance.get(`/releases/recent?page=${page}&size=${size}`),

  delete: (releaseId: string): Promise<AxiosResponse<ReleaseListResponse>> =>
    instance.delete(`/releases/${releaseId}`),

  deleteTrackFile: (fileName: string): Promise<AxiosResponse> =>
    instance.delete(`/releases/track_audio_files/${fileName}`),

  validations: (id: string): Promise<AxiosResponse<MHErrors>> =>
    instance.get(`/releases/${id}/validation`),

  releaseCount: (): Promise<AxiosResponse<ReleaseCount>> =>
    instance.get(`/releaseCount`),
};

/*
 * File upload
 *
 * When uploading a file follow this order:
 * 1. Generate signed url
 * 2. Use sigened url to save file to gcp bucket
 */
const file = {
  /* Safari prevents rerequesting this endpoint without interval so adding a uuid to ensure a unique query */
  generateSignedUrl: (
    fileType: UploadType
  ): Promise<AxiosResponse<SignedUploadUrlResponse>> =>
    instance.post(
      `/releases/${namespaceMap[fileType]}/signed_upload_url?uuid=${UUID()}`
    ),

  saveToGCPBucket: (
    signedUrl: string,
    fileData: File,
    config: AxiosRequestConfig
  ): Promise<AxiosResponse> => {
    const { withCredentials, headers, onUploadProgress } = config;
    return axios.put(signedUrl, fileData, {
      withCredentials,
      headers: { ...headers },
      onUploadProgress,
    });
  },
};

/**
 * Royalties
 */
const royalties = {
  royaltyReport: (
    dsp: StatementLineItem['dsp'],
    startDate: string,
    endDate: string
  ): Promise<AxiosResponse<Blob>> =>
    instance.get(
      `/royaltyReport?dsp=${dsp}&startDate=${startDate}&endDate=${endDate}`,
      {
        responseType: 'blob',
        headers: {
          Accept: 'text/csv',
        },
      }
    ),
};

/*
 * Payout
 */

interface SummaryParams {
  periodStart?: string;
  periodEnd?: string;
  topItemsLimit?: number;
  dspList?: Array<string>;
  territoryList?: Array<string>;
  trackId?: string;
  releaseId?: string;
}

const payout = {
  getSummary: (params: SummaryParams): Promise<AxiosResponse> =>
    instance.get(`/payouts/summary`, { params }),
};

/*
 * Artwork
 */

const artwork = {
  get: (id: string): Promise<AxiosResponse<ReleaseArtworkResponse>> => {
    return instance.get(`/releases/${id}/artwork`);
  },
  createImage: (
    id: string,
    artwork: ReleeaseArtworkRequest
  ): Promise<AxiosResponse<ReleaseArtworkResponse>> => {
    return instance.post(`/releases/${id}/artwork/image`, artwork);
  },
  delete: (id: string): Promise<AxiosResponse<void>> => {
    return instance.delete(`/releases/${id}/artwork/image`);
  },
};

/*
 * Tracks
 */

const tracks = {
  getAll: (
    statuses: Track['trackStatus'][] = [],
    page: number,
    size: number,
    search?: string
  ): Promise<AxiosResponse<TracklistPaginatedResponse>> => {
    return instance.get(
      `/tracks?page=${page}&size=${size}${search ? `&search=${search}` : ''}${
        statuses.length ? `&status=${statuses.join(',')}` : ''
      }`
    );
  },

  getSingleTrack: (trackId: string): Promise<AxiosResponse<TrackResponse>> => {
    return instance.get(`/tracks/${trackId}`);
  },

  getTrackWavealyzerFeedback: (
    trackId: string
  ): Promise<AxiosResponse<TrackAudioWavelyzerFeedbackResponse>> => {
    return instance.get(`/tracks/${trackId}/wavealyzerResults`);
  },

  createTrack: (track: TrackRequest): Promise<AxiosResponse<TrackResponse>> => {
    return instance.post('/tracks', track);
  },

  updateTrack: (
    id: string,
    track: TrackRequest
  ): Promise<AxiosResponse<TrackResponse>> => {
    return instance.put(`/tracks/${id}`, track);
  },

  patchTrack: (
    id: string,
    track: TrackPatchRequest
  ): Promise<AxiosResponse<TrackResponse>> => {
    return instance.patch(`/tracks/${id}`, track);
  },

  deleteTrack: (id: string): Promise<AxiosResponse<void>> => {
    return instance.delete(`/tracks/${id}`);
  },

  // Audio
  getTrackAudio: (id: string): Promise<AxiosResponse<TrackAudioResponse>> => {
    return instance.get(`/tracks/${id}/audio`);
  },

  getTrackAudioBlob: (fileUri: string): Promise<AxiosResponse<Blob>> => {
    return axios.get(fileUri, {
      responseType: 'blob',
    });
  },

  deleteTrackAudio: (id: string): Promise<AxiosResponse<void>> => {
    return instance.delete(`/tracks/${id}/audio`);
  },

  setTrackAudio: (
    id: string,
    trackAudio: TrackAudioRequest
  ): Promise<AxiosResponse<TrackAudioResponse>> => {
    return instance.post(`/tracks/${id}/audio`, trackAudio);
  },

  // Participants
  getTrackParticipants: (
    id: string
  ): Promise<AxiosResponse<TrackParticipantResponse[]>> =>
    instance.get(`/tracks/${id}/participants`),

  setTrackParticpants: (
    id: string,
    participants: TrackParticipantRequest[]
  ): Promise<AxiosResponse<TrackParticipantResponse[]>> => {
    return instance.post(`/tracks/${id}/participants`, participants);
  },

  uploadAudioFile: (id: string, targetUrl: string): Promise<void> => {
    return instance.post(`/tracks/${id}/audioUpload`, {
      targetUrl,
    });
  },

  storeSoundfileUploadId(trackId: string, soundfileId: string): Promise<void> {
    return instance.post(`/tracks/${trackId}/soundfileUpload`, {
      soundfileId: soundfileId,
    });
  },

  uploadCertificate: (id: string): Promise<void> => {
    return instance.post(`/tracks/${id}/uploadCertificates`, {});
  },
};

const account = {
  getUserProfile: (): Promise<AxiosResponse<UserProfile>> => {
    return instance.get('/userProfile');
  },

  getUserProfileValidations: (): Promise<AxiosResponse<MHProfileError[]>> => {
    return instance.get('/userProfile/validation');
  },

  setUserProfile: (
    profileData: UserProfile
  ): Promise<AxiosResponse<UserProfile>> => {
    // The API requires that all empty fields are null (i.e. not just emtpy white space)
    const keys: Array<keyof UserProfile> = Object.keys(profileData) as Array<
      keyof UserProfile
    >;
    const profileDataAsList = keys.map((profileAttribute) => {
      const currentProfileAttribute = profileData[profileAttribute];
      const updatedProfileAttribute =
        isString(currentProfileAttribute) && !!currentProfileAttribute?.trim()
          ? currentProfileAttribute?.trim()
          : currentProfileAttribute;
      return { [profileAttribute]: updatedProfileAttribute };
    });
    const requestObject = Object.assign({}, ...profileDataAsList);

    return instance.put('/userProfile', requestObject);
  },

  patchUserProfile: (
    updateRequest: UserProfilePatchRequest
  ): Promise<AxiosResponse<UserProfile>> =>
    instance.patch('/userProfile', updateRequest),

  getCustomToken: (
    gemaAccessToken: string,
    issuer: IssuerType = 'gemaCiam',
    ciamNonce?: string
  ): Promise<AxiosResponse<string>> => {
    return instance.get(`/exchange?issuer=${issuer}`, {
      baseURL:
        process.env.REACT_APP_GEMA_TOKEN_EXCHANGE_BASE_URL ??
        instance.defaults.baseURL,
      headers: {
        'X-SSO-Token': gemaAccessToken,
      },
      params: {
        nonce: ciamNonce,
      },
    });
  },
  getUserFeatures: (): Promise<AxiosResponse<UserFeatureResponse[]>> => {
    return instance.get('/users/features');
  },

  getExperiments: (): Promise<AxiosResponse<ExperimentResponse>> => {
    return instance.get('/experiments');
  },

  createProfileImageSignedUrl: (): Promise<
    AxiosResponse<SignedUploadUrlResponse>
  > => {
    return instance.post<SignedUploadUrlResponse>(
      `/userProfile/profileImageSignedUrl`
    );
  },
};

/**
 * BE Health Check
 */

export interface HealthResponse {
  status: string;
}

const healthCheck = {
  health: (): Promise<AxiosResponse<HealthResponse>> => {
    return instance.get('/actuator/health');
  },
};

/**
 * GEMA Auth
 */

export const gemaAuthMiddleware = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig<unknown>> => {
  if (config?.url && config.url.includes('/token')) {
    return config;
  }

  const gemaCiamAuthToken = await authentication.getGemaOktaAccessToken();

  logging.info({
    productArea: 'auth',
    message: `Making GEMA service request for gema id ${authentication.user?.issuerId} to ${config?.url}`,
    messageContext: {
      hasGemaCiamAuthToken: Boolean(gemaCiamAuthToken),
    },
  });

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${gemaCiamAuthToken}`,
    },
  };
};

const musicAnalytics = {
  getToken: (): Promise<AxiosResponse<MusicAnalyticsToken>> => {
    return instance.post('/musicanalytics/token');
  },
};

/**
 * GEMA Soundfile Upload
 */

const gemaSoundfileApi = axios.create({
  baseURL: process.env.REACT_APP_GEMA_SOUNDFILE_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

gemaSoundfileApi.interceptors.request.use(gemaAuthMiddleware, (error) => {
  Sentry.captureException(error);
  logging.error({ productArea: 'gema', message: error, error });
  return Promise.reject(error);
});

gemaSoundfileApi.interceptors.response.use(
  (response) => response,
  (error) => {
    logging.error({ productArea: 'gema', message: error, error });
    return Promise.reject(error);
  }
);

const soundfile = {
  createSoundfile: (
    soundFileDocJson: SoundfileDocRequest
  ): Promise<AxiosResponse<SoundfileDocResponse>> =>
    gemaSoundfileApi.put('/soundfiles', soundFileDocJson),

  getSoundfile: (
    trackId: string
  ): Promise<AxiosResponse<SoundfileGetResponseBody>> =>
    gemaSoundfileApi.get(`/soundfiles/${trackId}`),

  uploadSoundFileAudio: (
    signedUrl: string,
    fileData: File
  ): Promise<AxiosResponse> =>
    axios.put(signedUrl, fileData, {
      headers: {
        'Content-Type': 'audio/wav',
      },
    }),
};

const participants = {
  getAll: (): Promise<AxiosResponse<ParticipantListResponse>> =>
    instance.get('/participants'),

  create: (
    participant: ParticipantRequest
  ): Promise<AxiosResponse<ParticipantResponse>> =>
    instance.post('/participants', participant),

  update: (
    participantId: string,
    participant: ParticipantRequest
  ): Promise<AxiosResponse<ParticipantResponse>> =>
    instance.put(`/participants/${participantId}`, participant),

  getParticipantRoles: (): Promise<AxiosResponse<ParticipantRoles>> =>
    instance.get('/participantRoles'),

  getParticipantReleases: (
    participantId: string
  ): Promise<AxiosResponse<Array<ReleaseResponse>>> =>
    instance.get(`/participants/${participantId}/releases`),

  deleteParticipant: (participantId: string): Promise<void> =>
    instance.delete(`/participants/${participantId}`),
};

const api = {
  instance,
  artwork,
  royalties,
  release,
  file,
  payout,
  tracks,
  account,
  healthCheck,
  musicAnalytics,
  soundfile,
  participants,
};

export default api;
